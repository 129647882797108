export const baseTheme = {
  colors: {
    accentError100: '#fee7ef',
    accentError200: '#ffb3cd',
    accentError300: '#fc83ad',
    accentError400: '#fb518c',
    accentError500: '#f20d5d',
    accentError600: '#da0c54',
    accentError700: '#ae0440',
    accentError800: '#990035',
    accentError900: '#660024',
    accentMint100: '#e8fdf5',
    accentMint200: '#b8ffea',
    accentMint300: '#8ef6d8',
    accentMint400: '#5ff2ba',
    accentMint500: '#19e699',
    accentMint600: '#22c386',
    accentMint700: '#09aa6d',
    accentMint800: '#037c4e',
    accentMint900: '#004d3c',
    accentOrange100: '#fff5f2',
    accentOrange200: '#ffcdbd',
    accentOrange300: '#fda481',
    accentOrange400: '#fa8d3e',
    accentOrange500: '#f96a06',
    accentOrange600: '#c75505',
    accentOrange700: '#b04203',
    accentOrange800: '#953204',
    accentOrange900: '#662a00',
    accentPurple100: '#fcf2ff',
    accentPurple200: '#eeb3fe',
    accentPurple300: '#e281fd',
    accentPurple400: '#d54cfa',
    accentPurple500: '#c309f6',
    accentPurple600: '#9708bf',
    accentPurple700: '#7e0a9e',
    accentPurple800: '#62037c',
    accentPurple900: '#3c004d',
    accentSuccess100: '#f3fff2',
    accentSuccess200: '#e7ffe6',
    accentSuccess300: '#97f291',
    accentSuccess400: '#65d941',
    accentSuccess500: '#34d900',
    accentSuccess600: '#25cf1b',
    accentSuccess700: '#0ab300',
    accentSuccess800: '#099900',
    accentSuccess900: '#044d00',
    accentWarning100: '#fef9e6',
    accentWarning200: '#fffacc',
    accentWarning300: '#fff0a3',
    accentWarning400: '#ffe44d',
    accentWarning500: '#f9c206',
    accentWarning600: '#e6b100',
    accentWarning700: '#c78605',
    accentWarning800: '#956004',
    accentWarning900: '#4d3000',
    grayCold100: '#f5f8fa',
    grayCold200: '#edf0f2',
    grayCold300: '#cddae4',
    grayCold400: '#bfcdd9',
    grayCold500: '#8a9aa8',
    grayCold600: '#627484',
    grayCold700: '#475b6b',
    grayCold800: '#3d4851',
    grayCold900: '#001c33',
    grayNeutral100: '#ffffff',
    grayNeutral200: '#f8f7f8',
    grayNeutral300: '#e6e6e6',
    grayNeutral400: '#cccccc',
    grayNeutral500: '#9c9c9c',
    grayNeutral600: '#737373',
    grayNeutral700: '#595959',
    grayNeutral800: '#404040',
    grayNeutral900: '#0d0d0d',
    primary100: '#f5f9ff',
    primary200: '#e0eeff',
    primary300: '#99c7ff',
    primary400: '#338eff',
    primary500: '#0072ff',
    primary600: '#005bcc',
    primary700: '#004499',
    primary800: '#002e66',
    primary900: '#001733'
  },

  font: {
    family: '-apple-system, BlinkMacSystemFont, \'Inter\', \'Roboto\', \'Oxygen\', \'Ubuntu\', \'Cantarell\', \'Fira Sans\', \'Droid Sans\', \'Helvetica Neue\', sans-serif',
    size: '16px'
  },

  media: {
    extraLarge: '(max-width: 1140px)',
    large: '(max-width: 960px)',
    medium: 'max-width: 767.98px',
    small: '(max-width: 540px)',
  },

  // in px
  sizes: {
    header: {height: 56},
    container: {width: 1200},
    footer: {height: 128},
    modal: {width: 540},
  },

  // z-index
  order: {
    header: 50,
    modal: 100,
  }
};
