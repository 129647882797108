import styled from 'styled-components';
import {baseTheme} from '../../../styles/theme';

import {productPlaceholderSrc} from '../png-icons';

export const Container = styled.div`
  background: ${baseTheme.colors.primary200};
  padding: 8px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  
  img {
    max-width: 100%;
    max-height: 100%;
  }
`;

export const ProductPlaceholder = () => {
  return <Container>
    <img src={productPlaceholderSrc} alt={''}/>
  </Container>;
};
