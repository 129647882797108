import { Fragment } from "react";
import { useWidgetContext } from "../../WidgetContext";
import { Label, OldPrice, Price, Wrapper } from "./style";

export const TotalInfo = () => {
  const { offer } = useWidgetContext();

  return (
    <Wrapper>
      {offer?.price && <Label>Total:</Label>}
      {offer?.price?.type === "free" && <Price>Free</Price>}
      {offer?.price?.type === "one_time" && (
        <Fragment>
          {offer?.price?.old_price && (
            <OldPrice>${offer.price.old_price}</OldPrice>
          )}
          {offer?.price?.price && <Price>${offer.price.price}</Price>}
        </Fragment>
      )}
    </Wrapper>
  );
};
