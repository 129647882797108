import { baseFetch } from "./BaseFetch";
import {
  IPaymentRequest,
  IOffer,
  IOfferPaymentRequest,
  IOfferPaymentResponse,
  IPaymentIntent,
  IPaymentPaynet,
} from "../interfaces/offer";

export const getSecret = async (devMode: boolean) => {
  return await baseFetch<{}, { pub_key: string }>(
    "/integrations/stripe/secret",
    {},
    "GET",
    devMode
  );
};

export const getOffer = async (id: string, devMode: boolean) => {
  return await baseFetch<{}, IOffer>(`/offer/${id}`, {}, "GET", devMode);
};

export const getPaymentIntent = async (
  id: string,
  data: IPaymentRequest,
  devMode: boolean
) => {
  return await baseFetch<IPaymentRequest, IPaymentIntent>(
    `/offer/${id}/intent`,
    data,
    "POST",
    devMode
  );
};

export const getPaymentPaynet = async (
  id: string,
  data: IPaymentRequest,
  devMode: boolean
) => {
  return await baseFetch<IPaymentRequest, IPaymentPaynet>(
    `/offer/${id}/paynet`,
    data,
    "POST",
    devMode
  );
};

export const sendOfferData = async (
  id: string,
  data: IOfferPaymentRequest,
  devMode: boolean
) => {
  return await baseFetch<IOfferPaymentRequest, IOfferPaymentResponse>(
    `/offer/${id}/onetime`,
    data,
    "POST",
    devMode
  );
};

export const offerPaypalCheckout = async (
  offerId: string,
  data: IOfferPaymentRequest,
  devMode: boolean
) => {
  return await baseFetch<IOfferPaymentRequest, { order_id: string }>(
    `/offer/${offerId}/paypal`,
    data,
    "POST",
    devMode
  );
};
