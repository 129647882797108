import * as React from "react";
import { SVGProps } from "react";
const Svg4Icon24PxArrowLeft = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} {...props}>
    <path
      d="M3 11.941a.992.992 0 0 1 .291-.648l5-5 .094-.083a1 1 0 0 1 1.32 1.497L6.413 11h13.585a1 1 0 0 1 .117 1.993l-.117.007H6.412l3.293 3.293a1 1 0 0 1-1.32 1.497l-.094-.083-5-5A.997.997 0 0 1 3 12.059z"
      fill="currentColor"
    />
  </svg>
);
export default Svg4Icon24PxArrowLeft;
