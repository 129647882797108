import styled from "styled-components";
import { baseTheme } from "../../../styles/theme";

export const Container = styled.div`
  display: flex;
  font-size: 14px;
  border-bottom: 1px solid ${baseTheme.colors.grayCold300};
  padding: 8px 0;

  &:last-child {
    border-bottom: none;
  }
`;

export const Image = styled.div`
  width: 118px;
  height: 64px;
  flex-shrink: 0;
  background: ${baseTheme.colors.primary200};
  position: relative;
  border-radius: 2px;
  overflow: hidden;

  img {
    max-width: 100%;
  }

  @media (${baseTheme.media.medium}) {
    width: 80px;
    height: 45px;
  }
`;

export const Category = styled.div<{ bgColor: string; color: string }>`
  background: ${(props) => props.bgColor};
  border-radius: 12px;
  position: absolute;
  top: 4px;
  left: 4px;
  width: 32px;
  height: 22px;
  text-align: center;
  padding: 3px 0;
  color: ${(color) => color.color};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Info = styled.div`
  display: flex;
  padding-left: 8px;
  flex-grow: 1;
  @media (${baseTheme.media.medium}) {
    flex-direction: column;
  }
`;

export const Title = styled.div`
  margin-right: 16px;
  flex-grow: 1;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (${baseTheme.media.medium}) {
    font-size: 12px;
    line-height: 18px;
    margin-right: 0;
    margin-bottom: 4px;
  }
`;

export const Price = styled.div`
  display: flex;
  flex-wrap: nowrap;
  font-weight: 600;
  color: ${baseTheme.colors.grayCold700};
  min-width: 72px;
  justify-content: space-between;
`;

export const Count = styled.div`
  margin-right: 8px;
`;

export const Value = styled.div`
  @media (${baseTheme.media.medium}) {
    align-self: flex-end;
  }
`;
