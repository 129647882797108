import styled from 'styled-components';
import {baseTheme} from '../../../styles/theme';

export const Container = styled.div`
  background-color: ${baseTheme.colors.grayCold100};
  margin: 0 -3rem -3rem;
  padding: 2rem 3rem 3rem;
  border-radius: 0 0 8px 8px;
  display: flex;
  flex-direction: column;

  @media (${baseTheme.media.medium}) {
    margin: 0 -1rem -1rem;
    padding: 1rem 1rem 1.5rem;
  }
`;
