import styled from 'styled-components';
import {baseTheme} from '../../styles/theme';

export const BackButton = styled.button`
  display: flex;
  align-items: center;
  border: none;
  background: none;
  color: ${baseTheme.colors.primary600};
  font-weight: 600;
  font-size: 14px;
  padding: 0;
  cursor: pointer;
  margin-bottom: 32px;

  svg {
    margin-right: 4px;
  }

  @media (${baseTheme.media.medium}) {
    margin-bottom: 22px;
  }
`;
export const FormGroup = styled.div`
  margin-bottom: 16px;
`;
export const GroupTitle = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 8px;
`;
export const InputGroup = styled.div`
  margin-bottom: 16px;
  position: relative;
`;
export const Label = styled.div`
  font-size: 14px;
  line-height: 20px;
  min-height: 20px;
  color: ${baseTheme.colors.grayCold600};
  margin-bottom: 6px;
`;
export const Input = styled.input<{ error?: boolean }>`
  border: 1px solid ${props => props.error ? baseTheme.colors.accentError500 : baseTheme.colors.primary300};
  box-sizing: border-box;
  border-radius: 4px;
  display: block;
  width: 100%;
  padding: 5px 8px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: ${baseTheme.colors.grayCold900};
  background-color: #fff;
  background-clip: padding-box;
  appearance: none;
  transition: border-color .15s ease-in-out;

  &.error {
    border-color: ${baseTheme.colors.accentError500};
  }

  &:focus {
    outline: none;
  }
`;
