import { getFormStatus, validateForm } from "../../../helpers/helpers";
import { useWidgetContext } from "../../WidgetContext";
import { PayPalButton, PaypalOptions } from "react-paypal-button-v2";
import { offerPaypalCheckout } from "../../../API/api";
const REACT_APP_PAYPAL_CLIENT_ID =
  "Afpd0pg0_q0qXJa__rgQlrVDWt0I4rWND-xgwTQEzEeQhIMQo_6drvfgODVZjpWSYano2IjdKpmed8M9";
export const PaypalWrapper = () => {
  const { offerId, devMode, formData, setFormErrors, offer, setActiveStep } =
    useWidgetContext();
  const merchantId = offer?.paypal_merchant_id ?? undefined;
  const options: PaypalOptions = {
    clientId: REACT_APP_PAYPAL_CLIENT_ID,
  };
  if (merchantId) {
    options.merchantId = merchantId;
  }
  return (
    <PayPalButton
      options={options}
      createOrder={async () => {
        if (offerId) {
          try {
            const dataOrder = await offerPaypalCheckout(
              offerId,
              formData,
              devMode
            );
            return dataOrder.order_id;
          } catch (error) {
            const formErrors = validateForm(formData);
            delete formErrors.isTosAgree;
            if (!getFormStatus(formErrors)) {
              setFormErrors(formErrors);
            }
          }
        }
      }}
      onApprove={(_: any, actions: any) => {
        return actions.order.capture().then(function (details: any) {
          if (details.status === "COMPLETED") {
            setActiveStep?.("success");
          } else {
            setActiveStep?.("error");
          }
        });
      }}
    />
  );
};
