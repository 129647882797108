import styled, { createGlobalStyle } from "styled-components";
import { baseTheme } from "./theme";

export default createGlobalStyle`
  //* {
  //  box-sizing: border-box;
  //}
  //
  //*::before,
  //*::after {
  //  box-sizing: border-box;
  //}
  
  img {
    vertical-align: middle;
  }
  
  .widget-container {
    font-family: ${baseTheme.font.family};
    display: flex;
    flex-direction: column;
    background-color: ${baseTheme.colors.grayNeutral100};
    width: 100%;
    max-width: 560px;
    margin: auto;
    border-radius: 0.5rem;
    padding: 2.5rem 3rem 3rem;
    color: ${baseTheme.colors.grayCold900};
    letter-spacing: -0.003em;
    font-feature-settings: 'case' on;
    position: relative;
  }

  .row {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(-1 * var(--bs-gutter-y));
    margin-right: calc(-0.5 * var(--bs-gutter-x));
    margin-left: calc(-0.5 * var(--bs-gutter-x));
  }

  .row > * {
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
    margin-top: var(--bs-gutter-y);
  }

  .col-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .col-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  
  @media (min-width: 576px) {
    .col-sm-6 {
      flex: 0 0 auto;
      width: 50%;
    }

    .col-sm-3 {
      flex: 0 0 25%;
      max-width: 25%;
    }
  }

  @keyframes loader1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes loader3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes loader2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(24px, 0);
    }
  }
`;

export const Button = styled.button`
  font-family: ${baseTheme.font.family};
  background: ${baseTheme.colors.primary500};
  border-radius: 4px;
  border: none;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: ${baseTheme.colors.grayNeutral100};
  padding: 14px;
  cursor: pointer;
  transition: background-color 0.2s;
  margin-bottom: 1rem;

  &:hover {
    background-color: ${baseTheme.colors.primary400};
  }

  &:active {
    background-color: ${baseTheme.colors.primary600};
  }

  &[disabled] {
    cursor: default;
    background-color: ${baseTheme.colors.grayCold300};

    &:hover {
      background-color: ${baseTheme.colors.grayCold300};
    }
  }
`;

export const CallToAction = styled.button`
  font-family: ${baseTheme.font.family};
  background: ${baseTheme.colors.primary500};
  border-radius: 4px;
  border: none;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: ${baseTheme.colors.grayNeutral100};
  padding: 8px 24px 8px 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: background-color 0.2s;

  &:hover {
    background-color: ${baseTheme.colors.primary400};
  }

  &:active {
    background-color: ${baseTheme.colors.primary600};
  }

  &[disabled] {
    cursor: default;
    background-color: ${baseTheme.colors.grayCold300};

    &:hover {
      background-color: ${baseTheme.colors.grayCold300};
    }
  }

  svg {
    margin-right: 8px;
  }
`;
