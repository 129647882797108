import { Fragment, memo, useEffect, useState } from "react";
import { useWidgetContext } from "../WidgetContext";
import { ShippingForm } from "./ShippingForm";
import { ContactForm } from "./ContactForm";
import { Free } from "../common/free/Free";
import { Stripe } from "../common/stripe/Stripe";
import { Paynet } from "../common/paynet/Paynet";
import { Paypal } from "../common/paypal/Paypal";

interface IProps {
  goBack?(): void;
}

export const CheckoutForm = memo(({ goBack }: IProps) => {
  const { offer, formData } = useWidgetContext();

  const isPriceFree =
    offer &&
    offer.price &&
    (offer.price?.price === 0 || offer.price?.type === "free");
  const paymentMethod = offer?.price?.payment_method;
  const [isFree, setIsFree] = useState(isPriceFree);

  useEffect(() => {
    if (isPriceFree) {
      setIsFree(!formData.include_order_bump);
    }
  }, [formData.include_order_bump, isPriceFree]);

  return (
    <Fragment>
      <ContactForm goBack={goBack} />
      {offer?.setting_enable_shipping && <ShippingForm />}
      {paymentMethod === "stripe" && <Stripe />}
      {paymentMethod === "paynet" && <Paynet />}
      {paymentMethod === "paypal" && <Paypal />}
      {isFree && <Free />}
    </Fragment>
  );
});
