import React, { memo, useCallback, useEffect, useMemo, useState } from "react";

import { Brand, CloseButton, Container, Wrapper } from "./style";
import { EveractMono, Svg4Icon32PxCross } from "../UI/icons";

import { Cart } from "../cart/Cart";
import { WidgetContext } from "../WidgetContext";
import { CheckoutForm } from "../checkout-form/CheckoutForm";
import { IFormValidation, IWidgetForm } from "../../interfaces/widget-form";
import { OrderConfirmation } from "../common/order-confirmation/OrderConfirmation";
import { Loading } from "../UI/loading/Loading";
import { Steps, WidgetPlacement } from "../../App";
import { IOffer } from "../../interfaces/offer";
import { Overlay } from "../UI/overlay/Overlay";

interface IProps {
  widgetPlacement?: WidgetPlacement;
  activeStep?: Steps;
  offer: IOffer;
  offerId: string;
  showBrand?: boolean;
  loading: boolean;
  isGetSecret: boolean;
  setActiveStep?: (step: Steps) => void;
  toCheckout?: () => void;
  backToCart?: () => void;
  onClose?: () => void;
  devMode: boolean;
}

export const WidgetItem = memo(
  ({
    offer,
    offerId,
    widgetPlacement = "popup",
    activeStep,
    showBrand = true,
    loading,
    isGetSecret,
    setActiveStep,
    backToCart,
    toCheckout,
    onClose,
    devMode,
  }: IProps) => {
    const initialData = useMemo(() => {
      return {
        first_name: "",
        include_order_bump: false,
        last_name: "",
        phone_number: "",
        email: "",
        isTosAgree: false,
        ...(offer.setting_enable_shipping && {
          shipping_country: "",
          shipping_address_1: "",
          shipping_address_2: "",
          shipping_city: "",
          shipping_state: "",
          shipping_zip: "",
        }),
      };
    }, [offer.setting_enable_shipping]);

    const [formData, setFormData] = useState<IWidgetForm>(initialData);
    const [formErrors, setFormErrors] = useState<Partial<IFormValidation>>({});

    const closeModal = useCallback(() => {
      setFormErrors({});
      onClose?.();
      if (activeStep === "success" && offer?.action_redirect_after_checkout) {
        if (offer.action_redirect_after_checkout.in_same_tab) {
          window.location.replace(offer.action_redirect_after_checkout.url);
        } else {
          window.open(offer.action_redirect_after_checkout.url, "_blank");
        }
      }
      setActiveStep?.("cart");
    }, [
      activeStep,
      offer?.action_redirect_after_checkout,
      onClose,
      setActiveStep,
    ]);

    const handleInputChange = (e: React.FormEvent<HTMLInputElement>) => {
      const value =
        e.currentTarget.type === "checkbox"
          ? e.currentTarget.checked
          : e.currentTarget.value;
      setFormData({
        ...formData,
        [e.currentTarget.name]: value,
      });
      let error = value === "";
      if (e.currentTarget.name === "email") {
        error =
          value === "" ||
          !(value as string).match(/^([\w.%+-]+)@([\w-]+\.)+(\w{2,})$/i);
      }
      if (e.currentTarget.required) {
        setFormErrors({
          ...formErrors,
          [e.currentTarget.name]: error,
        });
      }
    };

    useEffect(() => {
      const urlParams = new URLSearchParams(window.location.search);
      const url = urlParams.get("status");
      const email = urlParams.get("email");
      if (url === "success" && email) {
        setActiveStep?.("success");
        setFormData({
          ...initialData,
          email: email,
        });
      }
      if (url === "error") {
        setActiveStep?.("error");
      }
    }, [initialData, setActiveStep]);

    const isBrand = showBrand && offer?.settings_hide_branding === false;

    return (
      <Overlay
        showOverlay={
          widgetPlacement === "popup" || widgetPlacement === "popup_without_btn"
        }
        bgColor={offer.settings_background_color}
      >
        <WidgetContext.Provider
          value={{
            handleInputChange,
            offer,
            offerId,
            formData,
            setFormData,
            formErrors,
            setFormErrors,
            setActiveStep,
            devMode,
            widgetPlacement,
            isGetSecret,
          }}
        >
          <Wrapper>
            <Container>
              {(widgetPlacement === "popup" ||
                ["error", "success", "unavailable"].includes(activeStep!)) && (
                <CloseButton onClick={closeModal}>
                  <Svg4Icon32PxCross />
                </CloseButton>
              )}
              <Loading loading={loading} />
              {activeStep === "cart" && !loading && (
                <Cart toCheckout={toCheckout} />
              )}
              {activeStep === "checkout" && (
                <CheckoutForm goBack={backToCart} />
              )}
              {["error", "success", "unavailable"].includes(activeStep!) && (
                <OrderConfirmation
                  type={activeStep as "success" | "error" | "unavailable"}
                />
              )}
            </Container>
            {isBrand && (
              <Brand>
                <EveractMono />
              </Brand>
            )}
          </Wrapper>
        </WidgetContext.Provider>
      </Overlay>
    );
  }
);
