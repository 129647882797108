import React, { createContext, useContext } from "react";
import { Steps, WidgetPlacement } from "../App";
import { IOffer } from "../interfaces/offer";
import { IFormValidation, IWidgetForm } from "../interfaces/widget-form";

interface IWidgetContext {
  handleInputChange(e: React.FormEvent<HTMLInputElement>): void;
  widgetPlacement: WidgetPlacement;
  offerId: string | null;
  offer?: IOffer | null;
  formData: IWidgetForm;
  devMode: boolean;
  setFormData: React.Dispatch<React.SetStateAction<IWidgetForm>>;
  formErrors: Partial<IFormValidation>;
  setFormErrors: React.Dispatch<React.SetStateAction<Partial<IFormValidation>>>;
  setActiveStep?: (step: Steps) => void;
  isGetSecret: boolean;
}

export const WidgetContext = createContext<IWidgetContext>({
  handleInputChange: () => {},
  isGetSecret: false,
  widgetPlacement: "popup",
  offerId: null,
  offer: null,
  formData: {
    first_name: "",
    include_order_bump: false,
    last_name: "",
    phone_number: "",
    email: "",
    isTosAgree: false,
  },
  devMode: false,
  setFormData: () => {},
  formErrors: {},
  setFormErrors: () => {},
  setActiveStep: () => {},
});

export const useWidgetContext = () => useContext(WidgetContext);
