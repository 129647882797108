import * as React from "react";
import { SVGProps } from "react";
const Svg4Icon24PxWarning = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 3c-.852 0-1.64.454-2.066 1.192L3.524 15.27A3.897 3.897 0 0 0 3 17.222C3 18.204 3.796 19 4.778 19h14.444c.982 0 1.778-.796 1.778-1.778 0-.685-.18-1.358-.524-1.952l-6.41-11.078A2.387 2.387 0 0 0 12 3Zm0 2c.138 0 .266.074.335.193l6.41 11.079.077.148c.07.15.12.309.149.472l.014.107H5.014l.015-.107c.038-.218.115-.427.226-.62l6.41-11.079A.387.387 0 0 1 12 5Zm0 8.75a1.25 1.25 0 1 1 0 2.5 1.25 1.25 0 0 1 0-2.5ZM12 8a1 1 0 0 1 .993.883L13 9v3a1 1 0 0 1-1.993.117L11 12V9a1 1 0 0 1 1-1Z"
      fill="#F20D5D"
    />
  </svg>
);
export default Svg4Icon24PxWarning;
