import * as React from "react";
import { SVGProps } from "react";
const Svg4Icon16PxLabel = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={16}
    height={16}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.353 5.525a1.5 1.5 0 1 0 2.122 2.121 1.5 1.5 0 0 0-2.122-2.12Zm.708 1.414a.5.5 0 1 1 .707-.707.5.5 0 0 1-.707.707Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.404 8.353a1.5 1.5 0 0 0 0 2.122l2.121 2.121a1.5 1.5 0 0 0 2.121 0l4.304-4.303a1.5 1.5 0 0 0 .439-1.06V5.938a1.5 1.5 0 0 0-.44-1.06l-.828-.829a1.5 1.5 0 0 0-1.06-.44H8.768a1.5 1.5 0 0 0-1.061.44L3.404 8.353Zm.707 1.415a.5.5 0 0 1 0-.707l4.303-4.304a.5.5 0 0 1 .354-.146h1.292a.5.5 0 0 1 .354.146l.829.829a.5.5 0 0 1 .146.353v1.293a.5.5 0 0 1-.146.354l-4.304 4.303a.5.5 0 0 1-.707 0L4.111 9.768Z"
      fill="currentColor"
    />
  </svg>
);
export default Svg4Icon16PxLabel;
