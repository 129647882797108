import { Svg4Icon24PxArrowLeft } from "../UI/icons";
import { useWidgetContext } from "../WidgetContext";
import {
  BackButton,
  FormGroup,
  GroupTitle,
  Input,
  InputGroup,
  Label,
} from "./style";

interface IProps {
  goBack?(): void;
}

export const ContactForm = ({ goBack }: IProps) => {
  const { handleInputChange, formErrors } = useWidgetContext();

  return (
    <>
      <BackButton onClick={goBack}>
        <Svg4Icon24PxArrowLeft />
        Cart
      </BackButton>
      <FormGroup>
        <GroupTitle>Contact information</GroupTitle>
        <div className="row">
          <InputGroup className={"col-12 col-sm-6"}>
            <Label>First name</Label>
            <Input
              name={"first_name"}
              error={formErrors.first_name}
              type={"text"}
              onChange={handleInputChange}
              required
            />
          </InputGroup>
          <InputGroup className={"col-12 col-sm-6"}>
            <Label>Last name</Label>
            <Input
              name={"last_name"}
              error={formErrors.last_name}
              onChange={handleInputChange}
              required
            />
          </InputGroup>
          <InputGroup className={"col-12 col-sm-6"}>
            <Label>Email address</Label>
            <Input
              name={"email"}
              error={formErrors.email}
              onChange={handleInputChange}
              type={"email"}
              required
            />
          </InputGroup>
          <InputGroup className={"col-12 col-sm-6"}>
            <Label>Phone number</Label>
            <Input
              name={"phone_number"}
              error={formErrors.phone_number}
              onChange={handleInputChange}
              required
            />
          </InputGroup>
        </div>
      </FormGroup>
    </>
  );
};
