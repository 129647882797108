import * as React from "react";
import { SVGProps } from "react";
const SvgThumbnailPlaceholder = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={288}
    height={162}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#thumbnail-placeholder_svg__a)">
      <rect width={288} height={162} rx={4} fill="#E0EEFF" />
      <path
        d="M193.449 15.714c5.543-9.524 19.399-9.524 24.942 0l49.883 85.715c5.543 9.523-1.386 21.428-12.471 21.428h-99.766c-11.085 0-18.013-11.905-12.471-21.428l49.883-85.715Z"
        fill="#F96A06"
      />
      <path
        d="M-28.8 86.857c0-14.401 0-21.602 2.825-27.102a25.818 25.818 0 0 1 11.328-11.238c5.544-2.803 12.802-2.803 27.319-2.803h61.056c14.517 0 21.775 0 27.319 2.803a25.815 25.815 0 0 1 11.328 11.237c2.825 5.501 2.825 12.702 2.825 27.103v60.572c0 14.401 0 21.602-2.825 27.102a25.817 25.817 0 0 1-11.328 11.238c-5.544 2.802-12.802 2.802-27.319 2.802H12.672c-14.517 0-21.775 0-27.32-2.802a25.82 25.82 0 0 1-11.327-11.238c-2.825-5.5-2.825-12.701-2.825-27.102V86.857Z"
        fill="#F9C206"
      />
      <ellipse
        opacity={0.75}
        cx={115.2}
        cy={-18.572}
        rx={115.2}
        ry={114.286}
        fill="#0072FF"
      />
    </g>
    <defs>
      <clipPath id="thumbnail-placeholder_svg__a">
        <rect width={288} height={162} rx={4} fill="#fff" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgThumbnailPlaceholder;
