import { memo } from "react";
import {
  Checkbox,
  CheckboxInput,
  CheckboxLabel,
} from "../one-time-offer/style";
import { useWidgetContext } from "../../WidgetContext";

export const TOS = memo(() => {
  const { offer, handleInputChange, formData } = useWidgetContext();
  return (
    <Checkbox>
      <CheckboxInput
        type="checkbox"
        name={"isTosAgree"}
        id={"tos"}
        required
        onChange={handleInputChange}
        checked={formData.isTosAgree}
      />
      <CheckboxLabel className={"checkbox-label"} htmlFor={"tos"}>
        I agree with the processing of personal data in accordance with the{" "}
        <a
          href={`https://${offer?.student_domain_url}/policies/terms`}
          target={"_blank"}
          rel="noreferrer"
        >
          Terms of Service
        </a>{" "}
        and{" "}
        <a
          href={`https://${offer?.student_domain_url}/policies/privacy`}
          target={"_blank"}
          rel="noreferrer"
        >
          Privacy Policy
        </a>
      </CheckboxLabel>
    </Checkbox>
  );
});
