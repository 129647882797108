import { Fragment, memo, ReactNode } from "react";

import { Container, WarningMessage } from "./style";
import { Svg4Icon24PxWarning } from "../../UI/icons";

import { OrderBump } from "../order-bump/OrderBump";
import { useWidgetContext } from "../../WidgetContext";
import { TotalInfo } from "./TotalInfo";
import { Loading } from "../../UI/loading/Loading";

interface IProps {
  loading: boolean;
  children?: ReactNode;
  disabled?: boolean;
}

export const Total = memo(({ loading, children, disabled }: IProps) => {
  const { offer } = useWidgetContext();

  return (
    <Container>
      <TotalInfo />
      {offer?.order_bump_is_active ? (
        <OrderBump>{children}</OrderBump>
      ) : (
        <Fragment>{children}</Fragment>
      )}
      {disabled && (
        <WarningMessage>
          <Svg4Icon24PxWarning />
          To place an order, you need to fill in all fields.
        </WarningMessage>
      )}
      <Loading loading={loading} overlay />
    </Container>
  );
});
