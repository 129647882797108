import React, { Fragment, memo, useCallback, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { getOffer } from "../../API/api";
import { Steps, WidgetPlacement } from "../../App";
import { IOffer } from "../../interfaces/offer";
import GlobalStyles, { CallToAction } from "../../styles/global";
import { Svg4Icon32PxCart } from "../UI/icons";
import { WidgetItem } from "./WidgetItem";

interface IProps {
  offerId: string;
  widgetPlacement: WidgetPlacement;
  activeStep: Steps;
  offerData?: IOffer | null;
  showBrand?: boolean;
  setActiveStep: (step: Steps) => void;
  toCheckout?: () => void;
  backToCart?: () => void;
  onClose?: () => void;
  devMode?: boolean;
}

export const Widget = memo(
  ({
    offerId,
    widgetPlacement,
    activeStep,
    offerData,
    showBrand,
    onClose,
    setActiveStep,
    backToCart,
    toCheckout,
    devMode = false,
  }: IProps) => {
    const [loading, setLoading] = useState(true);
    const [offer, setOffer] = useState<IOffer | null>(null);
    const [show, setShow] = useState(false);

    useEffect(() => {
      if (offerId && !offerData) {
        getOffer(offerId, devMode)
          .then((offer) => {
            if (offer.state === "published") {
              // Temporary solution for old offers that didn't have multiple payment systems
              if (
                offer.price &&
                offer.price.type !== "free" &&
                !offer.price.payment_method
              ) {
                offer.price = {
                  ...offer.price,
                  payment_method: "stripe",
                };
                setOffer(offer);
              } else {
                setOffer(offer);
              }
            } else {
              setActiveStep?.("unavailable");
            }
          })
          .catch((e) => {
            setActiveStep?.("unavailable");
            console.log("%c⇒ e", "color: #89DDF7", e);
          })
          .finally(() => {
            setLoading(false);
          });
      } else if (offerData) {
        setOffer(offerData);
        setLoading(false);
      } else {
        setLoading(false);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [offerId, setActiveStep]);

    const showPopup = useCallback(() => {
      window.history.replaceState("", "", window.location.pathname);
      setShow(true);
    }, []);

    const closePopup = useCallback(() => {
      setShow(false);
    }, []);

    return (
      <Fragment>
        <GlobalStyles />
        {widgetPlacement === "page" && offer && !loading && (
          <WidgetItem
            offerId={offerId}
            offer={offer}
            loading={loading}
            showBrand={showBrand}
            widgetPlacement={widgetPlacement}
            activeStep={activeStep}
            isGetSecret={!offerData}
            onClose={onClose}
            backToCart={backToCart}
            setActiveStep={setActiveStep}
            toCheckout={toCheckout}
            devMode={devMode}
          />
        )}
        {widgetPlacement === "popup" && (
          <Fragment>
            <CallToAction onClick={showPopup}>
              <Svg4Icon32PxCart />
              {loading ? "Loading..." : offer?.setting_call_to_action}
            </CallToAction>
            {offer &&
              show &&
              !loading &&
              ReactDOM.createPortal(
                <WidgetItem
                  offerId={offerId}
                  offer={offer}
                  loading={loading}
                  showBrand={showBrand}
                  widgetPlacement={widgetPlacement}
                  activeStep={activeStep}
                  isGetSecret={!offerData}
                  onClose={closePopup}
                  backToCart={backToCart}
                  setActiveStep={setActiveStep}
                  toCheckout={toCheckout}
                  devMode={devMode}
                />,
                document.querySelector("body") as Element
              )}
          </Fragment>
        )}
        {widgetPlacement === "popup_without_btn" && (
          <Fragment>
            {offer &&
              !loading &&
              ReactDOM.createPortal(
                <WidgetItem
                  offerId={offerId}
                  offer={offer}
                  loading={loading}
                  showBrand={showBrand}
                  widgetPlacement={widgetPlacement}
                  activeStep={activeStep}
                  isGetSecret={!offerData}
                  backToCart={backToCart}
                  setActiveStep={setActiveStep}
                  toCheckout={toCheckout}
                  devMode={devMode}
                />,
                document.querySelector("body") as Element
              )}
          </Fragment>
        )}
      </Fragment>
    );
  }
);
