import React from "react";
import {useWidgetContext} from '../WidgetContext';

interface IProps {
  size: {
    width: number;
    height: number;
  };
  bucket_name?: string;
  object_name?: string;
  url?: string | null;
  alt?: string;
  className?: string;
  imgProps?: React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement>;
  devMode?: boolean;
}

export const imageUrl = ({
                           bucket_name,
                           object_name,
                           size,
                           devMode
                         }: Pick<IProps, "bucket_name" | "object_name" | "size" | "devMode">) => {
  const domain = devMode ? 'https://img.dev.wisent.work' : 'https://img.everact.io';
  return `${domain}/AfrOrF3gWeDA6VOlDG4TzxMv39O7MXnF4CXpKUwGqRM/rs:auto:${size.height}:${size.width}:0/g:sm/plain/s3://${bucket_name}/${object_name}`;
};

export const Image = ({
                        bucket_name,
                        object_name,
                        size,
                        url,
                        alt = "",
                        className,
                        imgProps,
                      }: IProps) => {
  const {devMode} = useWidgetContext();
  const src = url ? url : imageUrl({bucket_name, object_name, size, devMode});
  return url || bucket_name ? (
    <img src={src} className={className} alt={alt} {...imgProps} />
  ) : null;
};
