import * as React from "react";
import { SVGProps } from "react";
const Svg4Icon32PxCross = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={32} height={32} {...props}>
    <path
      d="M22.364 9.636a1 1 0 0 1 .078 1.327l-.078.087-4.95 4.95 4.95 4.95a1 1 0 0 1-1.327 1.492l-.087-.078-4.95-4.95-4.95 4.95a1 1 0 0 1-1.492-1.327l.078-.087 4.95-4.95-4.95-4.95a1 1 0 0 1 1.327-1.492l.087.078 4.95 4.95 4.95-4.95a1 1 0 0 1 1.414 0z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
export default Svg4Icon32PxCross;
