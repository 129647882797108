import React, { useState } from "react";

import { IImage } from "../../../interfaces/offer";
import { Image } from "../../image/Image";
import { ThumbnailPlaceholder } from "../../UI/icons";

interface IProps {
  thumbnailCourse: IImage | null;
  width: number;
  height: number;
}

export const ThumbnailPlaceholderImage = ({
  height,
  width,
  thumbnailCourse,
}: IProps) => {
  const [thumbnail, setThumbnail] = useState(thumbnailCourse);

  const onError = () => {
    setThumbnail(null);
  };

  return (
    <>
      {thumbnail ? (
        <Image
          bucket_name={thumbnail.bucket_name}
          object_name={thumbnail.object_name}
          size={{ width, height }}
          alt={"thumbnail"}
          imgProps={{
            onError,
          }}
        />
      ) : (
        <ThumbnailPlaceholder width={118} height={64} viewBox={"0 0 288 162"} />
      )}
    </>
  );
};
