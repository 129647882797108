import { memo } from "react";
import {
  Container,
  Description,
  List,
  Name,
  NoProducts,
  OfferMessage,
  Title,
} from "./style";

import { Product } from "../common/product/Product";
import { useWidgetContext } from "../WidgetContext";
import { NoProductsPlaceholder } from "../UI/icons";
import { TotalInfo } from "../common/total/TotalInfo";
import { Button } from "../../styles/global";

interface IProps {
  toCheckout?(): void;
}

export const Cart = memo(({ toCheckout }: IProps) => {
  const { offer } = useWidgetContext();
  return (
    <>
      <Name>{offer?.title}</Name>
      <Description>{offer?.description}</Description>
      <Container>
        {offer?.products && offer.products.length > 0 && (
          <Title>Your cart</Title>
        )}
        <List>
          {offer?.products && offer.products.length > 0 ? (
            offer.products.map((product, index) => (
              <Product key={index} product={product} />
            ))
          ) : (
            <NoProducts>
              <NoProductsPlaceholder viewBox={"0 0 400 224"} />
              <div>
                Add products to your offer in <span>Products</span> tab.
              </div>
            </NoProducts>
          )}
        </List>
        <OfferMessage>{offer?.setting_price_display_overwrite}</OfferMessage>
        <TotalInfo />
        <Button onClick={toCheckout}>Continue</Button>
      </Container>
    </>
  );
});
