import React, { useContext } from "react";

import { WidgetContext } from "../../WidgetContext";
import {
  Container,
  Description,
  Header,
  OldPrice,
  Price,
  Title,
  Checkbox,
  CheckboxInput,
  CheckboxLabel,
} from "./style";

export const OneTimeOffer = () => {
  const { handleInputChange, offer, formData } = useContext(WidgetContext);

  return (
    <Container>
      <Header>
        <Title>{offer?.order_bump_headline}</Title>
        {offer?.order_bump_old_price && (
          <OldPrice>${offer.order_bump_old_price}</OldPrice>
        )}
        {offer?.order_bump_price && <Price>${offer.order_bump_price}</Price>}
      </Header>
      <Description>{offer?.order_bump_description}</Description>
      <Checkbox>
        <CheckboxInput
          type="checkbox"
          name={"include_order_bump"}
          id={"to-cart"}
          onChange={handleInputChange}
          checked={formData.include_order_bump}
        />
        <CheckboxLabel className={"checkbox-label"} htmlFor={"to-cart"}>
          {offer?.order_bump_checkbox_text}
        </CheckboxLabel>
      </Checkbox>
    </Container>
  );
};
