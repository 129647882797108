import styled from "styled-components";
import { baseTheme } from "../../styles/theme";

export const Container = styled.div`
  margin-bottom: 16px;
`;

export const CardInput = styled.div<{ error?: boolean }>`
  border: 1px solid
    ${(props) =>
      props.error
        ? baseTheme.colors.accentError600
        : baseTheme.colors.primary300};
  border-radius: 4px;
  padding: 7px 8px;
  margin-bottom: 16px;
  height: 32px;
`;
