import { Fragment, useCallback, useState } from "react";
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { StripeElementStyle } from "@stripe/stripe-js/types/stripe-js/elements/base";
import {
  StripeCardCvcElementChangeEvent,
  StripeCardExpiryElementChangeEvent,
  StripeCardNumberElementChangeEvent,
} from "@stripe/stripe-js";

import { CardInput, Container } from "./style";

import { Total } from "../common/total/Total";
import { useWidgetContext } from "../WidgetContext";
import { getFormStatus, validateForm } from "../../helpers/helpers";
import { getPaymentIntent } from "../../API/api";
import { ButtonPurchase } from "../common/button-purchase/ButtonPurchase";
import { usePayment } from "../common/hooks/use-payment";

const inputStyles: StripeElementStyle = {
  base: {
    fontFamily: '"Inter", sans-serif',
    color: "#212529",
    fontSize: "14px",
    fontWeight: "400",
  },
};

export const PaymentForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const {
    formData,
    offerId,
    formErrors,
    setFormErrors,
    setActiveStep,
    devMode,
  } = useWidgetContext();
  const { disabled, loading, setLoading } = usePayment();
  const [cardFilled, setCardFilled] = useState(false);

  const onStripePayment = (offerId: string) => {
    getPaymentIntent(offerId, formData, devMode)
      .then((result) => {
        if (
          result.status === "success.offer.payment_intent_created" &&
          result.client_secret
        ) {
          confirmPaymentStripe(result.client_secret).then();
        } else {
          setLoading(false);
          setActiveStep?.("unavailable");
        }
      })
      .catch(() => {
        setLoading(false);
        setActiveStep?.("unavailable");
      });
  };

  const handleSubmit = () => {
    const formErrors = validateForm(formData);

    delete formErrors.isTosAgree;
    if (!getFormStatus(formErrors)) {
      setFormErrors({
        ...formErrors,
        cardNumber: !cardFilled,
        cardExpiry: !cardFilled,
        cardCvc: !cardFilled,
      });
    } else if (offerId && cardFilled) {
      setLoading(true);
      onStripePayment(offerId);
    }
  };

  const confirmPaymentStripe = async (clientSecret: string) => {
    if (!stripe || !elements) {
      return;
    }
    const cardNumberElement = elements.getElement(CardNumberElement);
    if (cardNumberElement == null) {
      return;
    }

    const result = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: cardNumberElement,
      },
    });

    setLoading(false);
    if (result.error) {
      setActiveStep?.("error");
    } else {
      setActiveStep?.("success");
    }
  };

  const onChange = useCallback(
    (
      event:
        | StripeCardNumberElementChangeEvent
        | StripeCardExpiryElementChangeEvent
        | StripeCardCvcElementChangeEvent
    ) => {
      setCardFilled(true);
      setFormErrors({
        ...formErrors,
        [event.elementType]: !!event.error?.message || event.empty,
      });
    },
    [formErrors, setFormErrors]
  );

  return (
    <Fragment>
      <Container>
        <div className="row">
          <div className="col-12">
            <CardInput error={formErrors.cardNumber}>
              <CardNumberElement
                onChange={onChange}
                options={{
                  showIcon: true,
                  style: inputStyles,
                }}
              />
            </CardInput>
          </div>
          <div className="col-sm-3 col-6">
            <CardInput error={formErrors.cardExpiry}>
              <CardExpiryElement
                onChange={onChange}
                options={{ style: inputStyles }}
              />
            </CardInput>
          </div>
          <div className="col-3">
            <CardInput error={formErrors.cardCvc}>
              <CardCvcElement
                onChange={onChange}
                options={{ style: inputStyles }}
              />
            </CardInput>
          </div>
        </div>
      </Container>
      <Total loading={loading} disabled={disabled}>
        <ButtonPurchase disabled={disabled} onClick={handleSubmit} />
      </Total>
    </Fragment>
  );
};
