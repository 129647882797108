import { useWidgetContext } from "../WidgetContext";
import { FormGroup, GroupTitle, Input, InputGroup, Label } from "./style";

export const ShippingForm = () => {
  const { handleInputChange, formErrors } = useWidgetContext();
  return (
    <FormGroup>
      <GroupTitle>Shipping address</GroupTitle>
      <div className="row">
        <InputGroup className={"col-12"}>
          <Label>Country</Label>
          <Input
            name={"shipping_country"}
            error={formErrors.shipping_country}
            onChange={handleInputChange}
            required
          />
        </InputGroup>
        <InputGroup className={"col-12 col-sm-6"}>
          <Label>Street</Label>
          <Input
            name={"shipping_address_1"}
            error={formErrors.shipping_address_1}
            onChange={handleInputChange}
            required
          />
        </InputGroup>
        <InputGroup className={"col-12 col-sm-6"}>
          <Label />
          <Input
            name={"shipping_address_2"}
            error={formErrors.shipping_address_2}
            onChange={handleInputChange}
            required
          />
        </InputGroup>
        <InputGroup className={"col-12 col-sm-6"}>
          <Label>Town or City</Label>
          <Input
            name={"shipping_city"}
            error={formErrors.shipping_city}
            onChange={handleInputChange}
            required
          />
        </InputGroup>
        <InputGroup className={"col-12 col-sm-6"}>
          <Label>State</Label>
          <Input
            name={"shipping_state"}
            error={formErrors.shipping_state}
            onChange={handleInputChange}
            required
          />
        </InputGroup>
        <InputGroup className={"col-3"}>
          <Label>Zip</Label>
          <Input
            name={"shipping_zip"}
            error={formErrors.shipping_zip}
            onChange={handleInputChange}
            required
          />
        </InputGroup>
      </div>
    </FormGroup>
  );
};
