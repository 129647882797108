import { useEffect, useState } from "react";
import { getFormStatus } from "../../../helpers/helpers";
import { useWidgetContext } from "../../WidgetContext";

export const usePayment = () => {
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const { formErrors, offer } = useWidgetContext();

  useEffect(() => {
    if (offer?.price?.payment_method !== "paynet") {
      delete formErrors.isTosAgree;
    }
    setDisabled(!getFormStatus(formErrors));
  }, [formErrors, offer?.price?.payment_method]);

  return {
    setLoading,
    disabled,
    loading,
  };
};
