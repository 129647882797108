import React, { Fragment, memo, useCallback } from "react";
import {
  errorIconSrc,
  successIconSrc,
  unavailableIconSrc,
} from "../../UI/png-icons";
import { Svg4Icon24PxArrowLeft } from "../../UI/icons";
import { Body, Button, Container, Description, Text, Title } from "./style";
import { useWidgetContext } from "../../WidgetContext";

interface IProps {
  type: "success" | "error" | "unavailable";
}

export const OrderConfirmation = memo(({ type }: IProps) => {
  const { formData, setActiveStep } = useWidgetContext();
  const onClick = useCallback(() => {
    setActiveStep?.("cart");
  }, [setActiveStep]);

  const data = useCallback(() => {
    switch (type) {
      case "success":
        return {
          iconSrc: successIconSrc,
          title: (
            <Fragment>
              Your purchase was <br /> successful!
            </Fragment>
          ),
          description: "We send order confirmation to your email",
        };
      case "error":
        return {
          iconSrc: errorIconSrc,
          title: (
            <Fragment>
              We are sorry <br /> Something went wrong!
            </Fragment>
          ),
          description: (
            <Fragment>
              We haven't charged your card. <br /> You can try again!
            </Fragment>
          ),
        };
      case "unavailable":
        return {
          iconSrc: unavailableIconSrc,
          description: "This checkout is currently unavailable",
        };
    }
  }, [type]);

  return (
    <Container type={type}>
      <img src={data().iconSrc} alt={""} />
      {type !== "unavailable" && <Title type={type}>{data().title}</Title>}
      <Body type={type}>
        <Description type={type}>{data().description}</Description>
        {type === "success" && <Text>{formData.email}</Text>}
        {type === "error" && (
          <Button onClick={onClick}>
            <Svg4Icon24PxArrowLeft style={{ marginRight: "8px" }} />
            Back to cart
          </Button>
        )}
        {type === "unavailable" && <Text>Try again later</Text>}
      </Body>
    </Container>
  );
});
