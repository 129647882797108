import { memo, ReactNode } from "react";

import { Container } from "./style";

import { OneTimeOffer } from "../one-time-offer/OneTimeOffer";

interface IProps {
  children: ReactNode;
}

export const OrderBump = memo(({ children }: IProps) => {
  return (
    <Container>
      <OneTimeOffer />
      {children}
    </Container>
  );
});
