import styled from "styled-components";
import { baseTheme } from "../../styles/theme";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Name = styled.div`
  font-weight: 600;
  font-size: 32px;
  line-height: 36px;
  margin-bottom: 8px;
  @media (${baseTheme.media.medium}) {
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 4px;
  }
`;

export const Description = styled.div`
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 32px;
  @media (${baseTheme.media.medium}) {
    font-size: 12px;
    line-height: 18px;
    margin-bottom: 16px;
  }
`;

export const Title = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: ${baseTheme.colors.grayCold700};
  margin-bottom: 8px;
  @media (${baseTheme.media.medium}) {
    line-height: 20px;
    margin-bottom: 4px;
  }
`;

export const List = styled.div`
  background: ${baseTheme.colors.grayCold100};
  border: 1px solid ${baseTheme.colors.grayCold300};
  border-radius: 0.25rem;
  padding: 0.5rem 1rem;
  margin-bottom: 2rem;
  @media (${baseTheme.media.medium}) {
    margin-bottom: 1rem;
  }
`;

export const OfferMessage = styled.div`
  color: ${baseTheme.colors.grayCold700};
  font-size: 14px;
  margin-bottom: 0.5rem;
`;

export const NoProducts = styled.div`
  padding-top: 16px;
  padding-bottom: 8px;
  
  svg {
    display: block;
    max-width: 100%;
    margin: 0 auto 18px;
  }
  
  div {
    color: ${baseTheme.colors.grayCold500};
    text-align: center;
    font-size: 16px;
    line-height: 22px;
    
    span {
      color: ${baseTheme.colors.primary500};;
    }
  }
`;
