import styled from 'styled-components';
import {baseTheme} from '../../../styles/theme';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;
export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  margin-bottom: 8px;
`;
export const Label = styled.div`
  margin-right: auto;
  font-size: 24px;
  line-height: 36px;
  @media (${baseTheme.media.medium}) {
    font-size: 16px;
    line-height: 22px;
  }
`;
export const OldPrice = styled.div`
  position: relative;
  font-size: 24px;
  color: ${baseTheme.colors.grayCold500};
  padding: 0 4px;
  margin-right: 11px;

  @media (${baseTheme.media.medium}) {
    font-size: 14px;
  }

  &:before {
    content: "";
    border-bottom: 1px solid ${baseTheme.colors.grayCold500};
    box-shadow: 0 1px 0 ${baseTheme.colors.grayNeutral100};
    position: absolute;
    width: 100%;
    height: 50%;
    transform: rotate(-12deg);
    left: 0;
  }
`;
export const Price = styled.div`
  font-weight: bold;
  font-size: 24px;
  color: ${baseTheme.colors.primary500};
  @media (${baseTheme.media.medium}) {
    font-size: 16px;
  }
`;
export const WarningMessage = styled.div`
  display: flex;
  align-items: center;
  background: ${baseTheme.colors.accentError100};
  border: 1px solid ${baseTheme.colors.accentError300};
  box-sizing: border-box;
  border-radius: 4px;
  font-size: 14px;
  line-height: 20px;
  padding: 8px;
  margin-top: 24px;

  svg {
    margin-right: 8px;
  }
`;
