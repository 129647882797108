import React, { Fragment, memo, ReactNode } from "react";
import styled from "styled-components";

const Container = styled.div<{ bgColor?: string | null }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${(props) => props.bgColor || "rgba(0, 23, 51, 0.5)"};
  backdrop-filter: blur(5px);
  padding: 1rem;
  z-index: 9999;
`;
interface IProps {
  showOverlay?: boolean;
  bgColor?: string | null;
  children: ReactNode;
}
export const Overlay = memo(({ bgColor, showOverlay, children }: IProps) => {
  if (showOverlay) {
    return <Container bgColor={bgColor}>{children}</Container>;
  }
  return <Fragment>{children}</Fragment>;
});
