import * as React from "react";
import { SVGProps } from "react";
const Svg4Icon32PxCart = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={32}
    height={32}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21 23a2 2 0 1 1 0 4 2 2 0 0 1 0-4Zm-9 0a2 2 0 1 1 0 4 2 2 0 0 1 0-4ZM9.858 6l.461 3H26a1 1 0 0 1 .98 1.202l-.031.114-2 6a1 1 0 0 1-.833.677L24 17H11.55l.308 1.999L23 19a1 1 0 0 1 .993.883L24 20a1 1 0 0 1-.883.993L23 21H10.142L8.141 7.999 6 8a1 1 0 0 1-.993-.883L5 7a1 1 0 0 1 .883-.993L6 6h3.858Zm14.754 4.999L10.627 11l.615 4 12.037-.001 1.333-4Z"
      fill="#fff"
    />
  </svg>
);
export default Svg4Icon32PxCart;
