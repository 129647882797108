import styled from "styled-components";
import {baseTheme} from "../../styles/theme";

export const Wrapper = styled.div`
  width: 100%;
  min-width: 320px;
  margin: auto;
  max-width: 560px;
  border-radius: 0.5rem;

  * {
    box-sizing: border-box;
  }

  *::before,
  *::after {
    box-sizing: border-box;
  }

`;

export const Container = styled.div`
  font-family: ${baseTheme.font.family};
  display: flex;
  flex-direction: column;
  background-color: ${baseTheme.colors.grayNeutral100};
  border-radius: 0.5rem;
  padding: 2.5rem 3rem 3rem;
  color: ${baseTheme.colors.grayCold900};
  letter-spacing: -0.003em;
  font-feature-settings: "case" on;
  box-shadow: 0px 2px 15px 4px rgba(7, 7, 55, 0.06);
  position: relative;
  @media (${baseTheme.media.medium}) {
    padding: 1.5rem 1rem 1rem;
  }
`;
export const CloseButton = styled.button`
  position: absolute;
  border: none;
  background: none;
  cursor: pointer;
  top: 0.5rem;
  right: 0.5rem;
  color: ${baseTheme.colors.grayCold500};
  padding: 0;
  line-height: 0;
`;
export const Loading = styled.div`
  position: absolute;
  border: none;
  background: none;
  cursor: pointer;
  top: 0.5rem;
  right: 0.5rem;
  color: ${baseTheme.colors.grayCold500};
  padding: 0;
  line-height: 0;
`;

export const Brand = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1.5rem;

  img {
    margin-left: 0.5rem;
  }
`;
