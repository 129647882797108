import * as React from "react";
import { SVGProps } from "react";
const Svg4Icon16PxWebinar = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={16}
    height={16}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m10.403 2.007.081.02 4.16 1.385a.52.52 0 0 1 .118.93l-.082.042-1.24.516v1.924a.52.52 0 0 1-.204.412c-.28.215-.566.396-.857.542v3.2l1.101.002a.52.52 0 0 1 .06 1.036l-.06.004L2.52 12a.52.52 0 0 1-.06-1.036l.06-.003 1.039.002.001-4.418A1.04 1.04 0 0 1 4.6 5.506h2.599L7.2 4.9l-1.24-.516a.52.52 0 0 1-.05-.936l.086-.036 4.16-1.385a.52.52 0 0 1 .247-.02ZM7.199 6.544 4.6 6.545v4.156h6.74V8.148c-.27.057-.544.086-.82.086-.925 0-1.942-.324-3.058-.959a.52.52 0 0 1-.258-.39L7.2 6.824l-.001-.28ZM12.4 5.333l-1.88.783a.52.52 0 0 1-.322.025l-.078-.025-1.88-.783v1.183l.096.05c.763.392 1.446.598 2.047.626l.137.003c.61 0 1.21-.193 1.808-.587l.072-.05V5.333Zm-2.08-2.266-2.678.891 2.678 1.115 2.678-1.115-2.678-.891Z"
      fill="currentColor"
    />
  </svg>
);
export default Svg4Icon16PxWebinar;
