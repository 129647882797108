import { Fragment, useRef, useState } from "react";
import { getPaymentPaynet } from "../../../API/api";
import { getFormStatus, validateForm } from "../../../helpers/helpers";
import { IPaymentPaynet } from "../../../interfaces/offer";
import { PaynetForm } from "../../paynet-form/PaynetForm";
import { useWidgetContext } from "../../WidgetContext";
import { ButtonPurchase } from "../button-purchase/ButtonPurchase";
import { usePayment } from "../hooks/use-payment";
import { Total } from "../total/Total";

export const Paynet = () => {
  const { disabled, loading, setLoading } = usePayment();
  const [paynetData, setPaynetData] = useState<IPaymentPaynet>();
  const { formData, offerId, setFormErrors, setActiveStep, devMode } =
    useWidgetContext();
  const formRef = useRef<HTMLFormElement | null>(null);

  const onPaynetPayment = (offerId: string) => {
    getPaymentPaynet(offerId, formData, devMode)
      .then((result) => {
        if (result) {
          setPaynetData(result);
          formRef.current?.submit();
        } else {
          setLoading(false);
          setActiveStep?.("unavailable");
        }
      })
      .catch(() => {
        setLoading(false);
        setActiveStep?.("unavailable");
      });
  };

  const handleSubmit = () => {
    const formErrors = validateForm(formData);

    if (offerId) {
      if (!getFormStatus(formErrors)) {
        setFormErrors(formErrors);
      } else {
        setLoading(true);
        onPaynetPayment(offerId);
      }
    }
  };

  return (
    <Fragment>
      <Total loading={loading} disabled={disabled}>
        <ButtonPurchase disabled={disabled} onClick={handleSubmit} />
      </Total>

      {offerId && formData.email && (
        <PaynetForm
          ref={formRef}
          email={formData.email}
          originHost={window.location.origin}
          expiry_date={paynetData?.expiry_date}
          host={paynetData?.host}
          operation={paynetData?.operation}
          signature={paynetData?.signature}
        />
      )}
    </Fragment>
  );
};
