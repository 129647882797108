import {Category, Container, Count, Image, Info, Price, Title, Value,} from "./style";
import {Svg4Icon16PxLabel, Svg4Icon16PxWebinar} from "../../UI/icons";

import {IOfferProduct} from "../../../interfaces/offer";
import {memo} from "react";
import {baseTheme} from "../../../styles/theme";
import {ThumbnailPlaceholderImage} from "../util/ThumbnailPlaceholderImage";
import {ProductPlaceholder} from '../../UI/product-placeholder/ProductPlaceholder';

interface IProps {
  product: IOfferProduct;
}

export const Product = memo(({product}: IProps) => {
  return (
    <Container>
      <Image>
        {product.entity === 'product' && !product.fields?.image ?
          <ProductPlaceholder/> :
          <ThumbnailPlaceholderImage
            height={288}
            width={162}
            thumbnailCourse={product.fields?.image || null}
          />}
        <Category
          bgColor={
            product.entity === "course"
              ? baseTheme.colors.accentOrange100
              : baseTheme.colors.primary100
          }
          color={
            product.entity === "course"
              ? baseTheme.colors.accentOrange500
              : baseTheme.colors.primary500
          }
        >
          {product.entity === "course" ? (
            <Svg4Icon16PxWebinar/>
          ) : (
            <Svg4Icon16PxLabel/>
          )}
        </Category>
      </Image>
      <Info>
        <Title>{product?.fields?.title}</Title>
        <Price>
          <Count>×1</Count>
          <Value>
            {product.fields?.price ? `$${product.fields.price?.amount}` : ""}
          </Value>
        </Price>
      </Info>
    </Container>
  );
});
