import { forwardRef, Ref } from "react";
import { IPaymentPaynet } from "../../interfaces/offer";

export const PaynetForm = forwardRef(
  (
    {
      expiry_date,
      host,
      operation,
      signature,
      originHost,
      email,
    }: Partial<Omit<IPaymentPaynet, "status">> & {
      originHost: string;
      email: string;
    },
    formRef: Ref<HTMLFormElement>
  ) => {
    const urlSuccess = new URL(originHost);
    const urlError = new URL(originHost);

    urlSuccess.searchParams.set("status", "success");
    urlSuccess.searchParams.set("email", email);
    urlError.searchParams.set("email", email);
    urlError.searchParams.set("status", "error");
    if (window.location.pathname.includes("/offer/")) {
      urlSuccess.pathname = window.location.pathname;
      urlError.pathname = window.location.pathname;
    }

    return (
      <>
        <form
          method="POST"
          action={host}
          ref={formRef}
          style={{ height: 0, visibility: "hidden" }}
        >
          <input type="text" name="operation" value={operation} />
          <input
            type="text"
            name="LinkUrlSucces"
            value={urlSuccess.toString()}
          />
          <input type="text" name="LinkUrlCancel" value={urlError.toString()} />
          <input type="text" name="ExpiryDate" value={expiry_date} />
          <input type="text" name="Signature" value={signature} />
          <input type="text" name="Lang" value={window.navigator.language} />
          <input
            type="submit"
            value="Confirm order"
            id="button-confirm"
            data-loading-text=""
            className="sp-button sp-button--blue sp-button--large d-block m-auto"
          />
        </form>
      </>
    );
  }
);
