import React, { Fragment, memo } from "react";
import { Button } from "../../../styles/global";
import { useWidgetContext } from "../../WidgetContext";
import { TOS } from "../total/TOS";

interface IProps {
  onClick?(e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void;
  disabled: boolean;
}

export const ButtonPurchase = memo(({ disabled, onClick }: IProps) => {
  const { offer } = useWidgetContext();

  const showTos = offer?.price?.payment_method === "paynet";
  return (
    <Fragment>
      <Button onClick={onClick} disabled={disabled}>
        Place order
      </Button>
      {showTos && <TOS />}
    </Fragment>
  );
});
