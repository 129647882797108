import { sendOfferData } from "../../../API/api";
import { getFormStatus, validateForm } from "../../../helpers/helpers";
import { useWidgetContext } from "../../WidgetContext";
import { ButtonPurchase } from "../button-purchase/ButtonPurchase";
import { usePayment } from "../hooks/use-payment";
import { Total } from "../total/Total";

export const Free = () => {
  const { disabled, loading, setLoading } = usePayment();
  const { offerId, setFormErrors, formData, setActiveStep, devMode } =
    useWidgetContext();

  const handleSubmit = () => {
    const formErrors = validateForm(formData);
    delete formErrors.isTosAgree;
    setFormErrors(formErrors);
    if (offerId && getFormStatus(formErrors)) {
      setLoading(true);
      sendOfferData(offerId, formData, devMode)
        .then((result) => {
          if (result.status === "success.offer.payment_success") {
            setActiveStep?.("success");
          } else {
            setActiveStep?.("unavailable");
          }
        })
        .catch(() => {
          setActiveStep?.("unavailable");
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (
    <Total loading={loading} disabled={disabled}>
      <ButtonPurchase disabled={disabled} onClick={handleSubmit} />
    </Total>
  );
};
