import { IFormValidation, IWidgetForm } from "../interfaces/widget-form";

export const validateForm = (data: IWidgetForm) => {
  let formErrors: { [key: string]: boolean } = {};
  for (const field in data) {
    const currentField = data[field as keyof IWidgetForm];
    formErrors[field] = currentField === "";
    if (field === "isTosAgree" && currentField === false) {
      formErrors[field] = true;
    }
    if (field === "email") {
      formErrors[field] =
        currentField === "" ||
        !data[
          field as keyof Omit<IWidgetForm, "include_order_bump" | "isTosAgree">
        ]?.match(/^([\w.%+-]+)@([\w-]+\.)+(\w{2,})$/i);
    }
  }
  return formErrors;
};

export const getFormStatus = (data: Partial<IFormValidation>) => {
  return Object.values(data).every((value) => !value);
};

export const getTypeValues = (
  type: string,
  success: string,
  error: string,
  unavailable: string
) => {
  switch (type) {
    case "success":
      return success;
    case "error":
      return error;
    case "unavailable":
      return unavailable;
  }
  return success;
};

export const fontLoader = () => {
  const headID = document.getElementsByTagName("head")[0];
  const link1 = document.createElement("link");
  link1.rel = "preconnect";
  link1.classList.add("everact-link-1");
  headID.appendChild(link1);
  link1.href = "https://fonts.googleapis.com";
  const link2 = document.createElement("link");
  link2.rel = "preconnect";
  link2.crossOrigin = "anonymous";
  link2.classList.add("everact-link-2");
  headID.appendChild(link2);
  link2.href = "https://fonts.gstatic.com";
  const link3 = document.createElement("link");
  link3.rel = "stylesheet";
  link3.crossOrigin = "anonymous";
  link3.classList.add("everact-link-3");
  headID.appendChild(link3);
  link3.href =
    "https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap";
};
