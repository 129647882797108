import styled from 'styled-components';
import {baseTheme} from '../../../styles/theme';

export const Container = styled.div`
  background: ${baseTheme.colors.grayNeutral100};
  box-shadow: 0 2px 15px 4px rgba(7, 7, 55, 0.06);
  border-radius: 4px;
  padding: 24px;
  margin-bottom: 16px;
`;

export const Header = styled.div`
  display: flex;
  margin-bottom: 16px;
  align-items: center;
`;

export const Title = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: ${baseTheme.colors.primary500};
  flex-grow: 1;
`;

export const OldPrice = styled.div`
  position: relative;
  color: ${baseTheme.colors.grayCold500};
  padding: 0 0.25rem;
  margin-right: 11px;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;

  &:before {
    content: "";
    border-bottom: 1px solid ${baseTheme.colors.grayCold500};
    box-shadow: 0 1px 0 ${baseTheme.colors.grayNeutral100};
    position: absolute;
    width: 100%;
    height: 50%;
    transform: rotate(-12deg);
    left: 0;
  }
`;

export const Price = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: ${baseTheme.colors.grayCold700};
`;

export const Description = styled.div`
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 16px;
`;

export const Checkbox = styled.div`
  position: relative;
  min-height: 1.5rem;
  display: flex;
  align-items: center;
`;
export const CheckboxInput = styled.input`
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0;
  box-sizing: border-box;
  padding: 0;

  &:checked ~ .checkbox-label {
    &:before {
      border-color: #99c7ff;
      color: #fff;
    }

    &:after {
      background-color: transparent;
      background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij4KICAgIDxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgZmlsbD0iIzAwNzJmZiI+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik0xNy4yOTMgOC43OTNjLjM5LS4zOSAxLjAyNC0uMzkgMS40MTQgMCAuMzYuMzYuMzg4LjkyOC4wODMgMS4zMmwtLjA4My4wOTQtNyA3Yy0uMzYuMzYtLjkyOC4zODgtMS4zMi4wODNsLS4wOTQtLjA4My00LTRjLS4zOS0uMzktLjM5LTEuMDI0IDAtMS40MTQuMzYtLjM2LjkyOC0uMzg4IDEuMzItLjA4M2wuMDk0LjA4M0wxMSAxNS4wODVsNi4yOTMtNi4yOTJ6Ii8+CiAgICAgICAgPC9nPgogICAgPC9nPgo8L3N2Zz4K');
      background-size: 24px;
      background-position: -4px -4px;
    }
  }
`;
export const CheckboxLabel = styled.label`
  position: relative;
  margin-bottom: 0;
  font-size: 14px;
  color: #475b6b;
  padding-left: 1.5rem;
  cursor: pointer;
  line-height: 1.5rem;
  min-height: 1.5rem;
  user-select: none;

  &:before {
    border-radius: 0.25rem;
    color: #fff;
    border: 1px solid #99c7ff;
    pointer-events: none;
  }

  &:after {
    display: block;
    background-size: 100%;
  }

  &:after,
  &:before {
    position: absolute;
    top: 0.2rem;
    left: 0;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
  }
`;
