import { usePayment } from "../hooks/use-payment";
import { Total } from "../total/Total";
import { PaypalWrapper } from "./PaypalWrapper";

export const Paypal = () => {
  const { disabled, loading } = usePayment();

  return (
    <Total loading={loading} disabled={disabled}>
      <PaypalWrapper />
    </Total>
  );
};
