import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Fragment, useEffect, useMemo, useState } from "react";
import { getSecret } from "../../../API/api";
import { GroupTitle } from "../../checkout-form/style";
import { PaymentForm } from "../../payment/PaymentForm";
import { useWidgetContext } from "../../WidgetContext";

export const Stripe = () => {
  const [publishableKey, setPublishableKey] = useState<string>();
  const { offer, devMode, isGetSecret } = useWidgetContext();

  const stripePromise = useMemo(
    () =>
      publishableKey && offer?.account_id
        ? loadStripe(publishableKey, {
            locale: "en",
            stripeAccount: offer.account_id,
          })
        : null,
    [offer?.account_id, publishableKey]
  );

  useEffect(() => {
    if (isGetSecret && offer?.price?.payment_method === "stripe") {
      getSecret(devMode)
        .then((result) => {
          setPublishableKey(result.pub_key);
        })
        .catch((e) => {
          console.log("%c⇒ e", "color: #FF5370", e);
        });
    }
  }, [devMode, isGetSecret, offer?.price?.payment_method]);

  return (
    <Elements stripe={stripePromise} options={{ locale: "en" }}>
      <Fragment>
        <GroupTitle>Credit card info</GroupTitle>
        <PaymentForm />
      </Fragment>
    </Elements>
  );
};
