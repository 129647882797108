import querystring from "query-string";
import axios from "axios";

export type IHttpMethods = "GET" | "POST" | "PUT" | "DELETE";

export const baseFetch = async <P, R>(
  url: string,
  params: P,
  method: IHttpMethods = "GET",
  devMode: boolean,
  headers: { [key: string]: string } = {}
): Promise<R> => {
  try {
    const bodyObj = method !== "GET" ? { data: params } : {};
    const config = { headers: { "Cache-Control": "no-cache" } };
    const axiosInstance = axios.create(config);
    const hasParams = method === "GET" && Object.keys(params as any).length > 0;
    const queryParams = `${hasParams ? "?" : ""}${
      hasParams ? querystring.stringify(params as any) : ""
    }`;
    const res = await axiosInstance(
      devMode
        ? `https://api.dev.wisent.work${url}${queryParams}`
        : `https://api.everact.io${url}${queryParams}`,
      {
        method,
        ...bodyObj,
        headers: {
          ...headers,
        },
      }
    );

    return await res.data;
  } catch (error) {
    console.log(`%c⇒ ${error}`, "color: #FF5370");
    throw error;
  }
};
