import styled from "styled-components";
import { baseTheme } from "../../../styles/theme";
import { Fragment, memo } from "react";

export const Overlay = styled.div<{ overlay: boolean }>`
  position: ${({ overlay }) => (overlay ? "absolute" : "relative")};
  z-index: 101;
  background-color: rgba(255, 255, 255, 0.6);
  display: flex;
  height: ${({ overlay }) => (overlay ? "auto" : "400px")};
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 8px;
`;

export const Loader = styled.div`
  margin: auto;
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;

  div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: ${baseTheme.colors.primary500};
    animation-timing-function: cubic-bezier(0, 1, 1, 0);

    &:nth-child(1) {
      left: 8px;
      animation: loader1 0.6s infinite;
    }

    &:nth-child(2) {
      left: 8px;
      animation: loader2 0.6s infinite;
    }

    &:nth-child(3) {
      left: 32px;
      animation: loader2 0.6s infinite;
    }

    &:nth-child(4) {
      left: 56px;
      animation: loader3 0.6s infinite;
    }
  }
`;

interface IProps {
  loading: boolean;
  overlay?: boolean;
}

export const Loading = memo(({ loading, overlay = false }: IProps) => {
  return (
    <Fragment>
      {loading && (
        <Overlay overlay={overlay}>
          <Loader>
            <div />
            <div />
            <div />
            <div />
          </Loader>
        </Overlay>
      )}
    </Fragment>
  );
});
