import styled from 'styled-components';
import {getTypeValues} from '../../../helpers/helpers';
import {baseTheme} from '../../../styles/theme';

export const Container = styled.div<{ type: string }>`
  background-color: ${({type}) => getTypeValues(type, baseTheme.colors.grayCold100, baseTheme.colors.accentWarning100, baseTheme.colors.grayNeutral200)};
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: ${props => props.type !== 'unavailable' ? '48px' : '76px'};
  margin: -40px -48px -48px;
  @media (${baseTheme.media.medium}) {
    margin: -24px -16px -16px;
  }
`;

export const Title = styled.div<{ type: string }>`
  color: ${props => props.type === 'success' ? baseTheme.colors.primary500 : baseTheme.colors.accentOrange900};
  font-weight: 600;
  font-size: 32px;
  line-height: 36px;
  text-align: center;
  padding: 24px 0;
`;

export const Body = styled.div<{ type: string }>`
  background-color: #fff;
  width: 100%;
  color: #475B6B;
  text-align: center;
  padding: ${({type}) => getTypeValues(type, '32px 40px 48px', '40px', '72px 16px 72px')};
  border-radius: 0 0 8px 8px;
`;

export const Description = styled.div<{ type: string }>`
  font-size: ${({type}) => getTypeValues(type, '14px', '24px', '16px')};
  line-height: ${({type}) => getTypeValues(type, '20px', '36px', '22px')};
  margin-bottom: ${({type}) => getTypeValues(type, '4px', '16px', '0')};
`;

export const Text = styled.div`
  font-size: 24px;
  line-height: 36px;
`;

export const Button = styled.button`
  font-family: ${baseTheme.font.family};
  background: ${baseTheme.colors.primary500};
  border-radius: 4px;
  border: none;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #fff;
  padding: 0.5rem;
  cursor: pointer;
  transition: background-color 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  &:hover {
    background: ${baseTheme.colors.primary500};
  }

  &:active {
    background-color: ${baseTheme.colors.primary600};
  }
`;
